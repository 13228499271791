<template lang='pug'>
.container(v-loading.fullscreen.lock='loading')
  video.vPlayer(ref="videoPlayer" loop :src="videoSrc" type="video/mp4")

  el-dialog.info-dialog(:visible.sync="infoVisible")
    .info
      img.logo(alt="Vue logo" src="../assets/banner.png")
      h2.title 點點星月光光
      h2.title Twinkle Twinkle Little Star
      h4.title-sub 沃手工作
      h3 請開啟您的手機音量
      h3 並保持電話螢幕亮起
    el-button(@click='start') 確認
</template>

<script>
// @ is an alias to /src
// import * as Tone from 'tone'

export default {
  name: 'Home',
  components: {
    // Tone,
  },
  data() {
    return {
      socket: '',
      loading: false,
      checkVisible: true,
      infoVisible: true,
      startVisible: true,
      music: null,
      timeout: null,
      videoSrc: '/video/ttls-b.mp4',
      soundSrc: '',
      currentTime: 0,
    }
  },
  watch: {
  },
  computed: {
    player() {
      return this.$refs.videoPlayer;
      // return this.$refs.videoPlayer.player;
    },
  },
  created() {
    // this.id = this.$route.query.id;
    // this.passcode = this.$route.query.passcode;
    // console.log('query :>> ', this.id, this.passcode);
  },
  mounted () {
    
    // eslint-disable-next-line no-undef
    // this.socket = io('https://localhost:7070', {
    // eslint-disable-next-line no-undef
    this.socket = io('https://twinkle-twinkle-little-star.winnowork.com:7070', {
      withCredentials: true,
      extraHeaders: {
        'twinkle-twinkle-little-star': 'abcd',  
      },
      query: {
        'client': 'mobile',
      },
    });

    this.socket.on('connect', () => {
      console.log('socket.io is connected');
      this.socket.emit('onMobileConnected');
    });

    this.socket.on('getTrackA', (time) => {
      console.log('getTrackA.', time);
      this.currentTime = time;
      this.loading = false;
      // this.startCheck = true;
    });

  },
  destroyed() {
    this.socket.disconnect();
  },
  methods: {
    
    start() {
      this.infoVisible = false;
      // const src = '/video/ttls-b.mp4';
      // this.playVideo(src);
      this.socket.emit('onMobileConnected');
      setTimeout(() => {
        this.player.pause();
        this.player.currentTime = this.currentTime;
        this.player.play();
        console.log('currentTime :>> ', this.player.currentTime);  
      }, 500);
    },
  },
}
</script>

<style lang='scss' scoped>
.container {
  // background-image: url('/img/bg.jpeg');
  background-color: rgb(0, 0, 0);
  height: auto;
  min-height: 100vh;
  width: 100vw;
  padding-bottom: 100px;
  
  .logo {
    width: 100%;
  }

  .title {
    color: rgb(26, 26, 26);
    margin: 0px;
  }

  .title-sub{
    color: rgb(26, 26, 26);
    margin-bottom: 20px;
  }

  .check {
    padding-top: 10vh;
    margin-left: auto;
    margin-right: auto;
    width: 100vw;
  }

  .el-card {
    margin-top: 20px;
    background-color: rgb(0 0 0 / 0%);
    border: 1px solid rgb(0 0 0 / 0%);
    color: white;
  }

  .last-card{
    margin-bottom: 50px;
  }

  .modeSelect {
    text-align: left;

    .modeItem {
      padding: 10px;
    }
  }

  .el-slider {
    margin-top: 10px;

    
  }

  .vPlayer {
    height: 100vh;
    width: 100%;
  }

  .video-player-box {
    height: 100vh;
  }
  .vjs-custom-skin {
    ::v-deep .video-js {
      // width: 100vw !important;
      // height: 100vh !important;
    }
  }
  ::v-deep .el-dialog {
    width: 90vw;
  }
  ::v-deep .el-dialog__header{
    opacity: 0;
    display: none;
  }
  ::v-deep .el-dialog__body{
    text-align: center;
    padding: 0;
    padding-bottom: 10px;
  }

  .tip-screen {
    margin-top: 20px;
    color: rgb(255, 98, 0);
  }

  .fade-enter-active, .fade-leave-active {
  transition: opacity .05s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>